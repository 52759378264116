<app-tabs-picker-toolbar>
  <div class="colored-menu-trigger" [matMenuTriggerFor]="hubMenu">
    <app-config-icon
      iconIdentifier="storage"
      [matTooltip]="'hub' | translate"
    ></app-config-icon>
    <div>{{ selectedHub?.name || 'allHubs' | translate }}</div>
    <app-config-icon iconIdentifier="expand_more"></app-config-icon>
    <mat-menu #hubMenu="matMenu" class="hub-menu">
      <button
        mat-menu-item
        class="mat-menu-item-flex"
        (click)="onSelectHub(null)"
      >
        <span>{{ 'allHubs' | translate }}</span>
      </button>
      <button
          mat-menu-item
          *ngFor="let hub of hubs"
          class="mat-menu-item-flex"
          (click)="onSelectHub(hub)"
        >
          <span>{{ hub.name }}</span>
        </button>
    </mat-menu>
  </div>
  <button
    mat-raised-button
    color="primary"
    (click)="onAdd()"
    >
    <app-config-icon iconIdentifier="add"></app-config-icon>
    {{ 'addEmail' | translate}}
  </button>
</app-tabs-picker-toolbar> <!-- .cc-header -->
<app-grid
  tableId="cc-framework"
  [columnDefs]="columns"
  [rowData]="rows"
  [gridOptions]="gridOptions"
  [noGridFilterTopPadding]="true"
></app-grid>
