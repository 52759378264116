import {Component, OnDestroy, OnInit} from "@angular/core";
import {combineLatest, Observable, of, Subscription} from "rxjs";
import {CodeTableEntry, LicenseService, User, UserRoleType, UserService} from "../../../../api/core";
import {TranslateService} from "@ngx-translate/core";
import {GlobalService} from "../../../../services/global.service";
import {CodeTableService} from "../../../../services/code-table.service";
import {DataService} from "../../../../services/data.service";
import {ColDef, GridApi, GridOptions, GridReadyEvent} from "ag-grid-community";
import {
  genBooleanColumn,
  genCodeTableColumn,
  genTextColumnWithAutoCompleteFilter
} from "../../../../util/grid/grid-renderer.util";
import {I18n} from "../../../../services/i18n.service";
import {ECodeTables} from "../../../../util/enum";
import {GridResetEvent} from "../../../../shared/grid/grid.component";
import {hashCode} from "../settings.component";

type StatsEntry = {
  label: string;
  value: string | number;
}
// UserWithRoleType extends User and adds a roleType property, meant to be used in the grid
type UserWithRoleType = User & {
  roleType: CodeTableEntry;
}

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html'
})
export class UserSettingsComponent implements OnInit, OnDestroy {

  usersColumnDefs: ColDef[] = [
    genTextColumnWithAutoCompleteFilter({
      field: 'username',
      headerName: I18n.getColName('username'),
      autoCompleteParams: () => this.users.map(u => u.username),
    }),
    genTextColumnWithAutoCompleteFilter({
      field: 'fullname',
      headerName: I18n.getColName('fullName'),
      autoCompleteParams: () => this.users.map(u => u.fullname),
    }),
    genTextColumnWithAutoCompleteFilter({
      field: 'email',
      headerName: I18n.getColName('email'),
      autoCompleteParams: () => this.users.map(u => u.email),
    }),
    genTextColumnWithAutoCompleteFilter({
      field: 'company',
      headerName: I18n.getColName('company'),
      autoCompleteParams: () => this.users.map(u => u.company),
    }),
    genTextColumnWithAutoCompleteFilter({
      field: 'department',
      headerName: I18n.getColName('department'),
      autoCompleteParams: () => this.users.map(u => u.department),
    }),
    {
      ...genCodeTableColumn({
        field: 'roleType',
        observable: this.getUserRoleTypesAsCodeTable(),
        headerName: I18n.getColName('role'),
      }),
      floatingFilter: true,
      sortable: true,
    },
    {
      ...genBooleanColumn(
        'closed',
        this.translateService.instant('closed'),
        this.translateService
      ), // needed because cellType inferred before transformation to text
      cellDataType: 'text',
    },
    {
      ...genBooleanColumn(
        'licensed',
        this.translateService.instant('licensed'),
        this.translateService
      ), // needed because cellType inferred before transformation to text
      cellDataType: 'text',
    },
    {
      ...genTextColumnWithAutoCompleteFilter({
        field: 'title',
        headerName: I18n.getColName('title'),
        autoCompleteParams: () => this.users.map(u => u.title),
      }),
      hide: true,
    },
    {
      ...genTextColumnWithAutoCompleteFilter({
        field: 'phoneNumber',
        headerName: I18n.getColName('phoneNumber'),
        autoCompleteParams: () => this.users.map(u => u.phoneNumber),
      }),
      hide: true,
    },
    {
      ...genTextColumnWithAutoCompleteFilter({
        field: 'workplace.description',
        headerName: I18n.getColName('workplace'),
        autoCompleteParams: () => this.users.map(u => u.workplace?.description),
      }),
      hide: true,
    },
    {
      ...genCodeTableColumn({
        field: 'hubs',
        headerName: this.translateService.instant('hub'),
        observable: this.codeTableService.getCodeTable(ECodeTables.hub),
      }),
      valueFormatter: (params) =>
        [...new Set(params.value?.map((d) => d.name))].join(', '),
      hide: true,
    },
  ];

  usersGridOptions: GridOptions = {
    rowHeight: 36,
    suppressContextMenu: true,
    suppressCellFocus: true,
    paginationAutoPageSize: true,
    onGridReady: (event: GridReadyEvent<User>) => {
      this.onGridReady(event);
    }
  };

  subscriptions: Subscription[] = [];
  statEntries: StatsEntry[] = [];
  users: UserWithRoleType[] = [];
  private userRoleTypesAsCodeTable: CodeTableEntry[] = [];

  constructor(
    protected readonly translateService: TranslateService,
    protected readonly globalService: GlobalService,
    protected readonly userService: UserService,
    protected readonly licenseService: LicenseService,
    protected readonly codeTableService: CodeTableService,
    protected readonly dataService: DataService,
  ) {
  }

  ngOnInit() {
    combineLatest([
      this.userService.getAllUsers(),
      this.userService.getUserRoleCount(),
      this.licenseService.getLicenseCount(),
    ]).subscribe({
      next: ([users, roleCount, licenseCount]) => {
        this.users = users.map((user) => {
          return {...user, roleType: this.userRoleTypeToCodeTableEntry(user.role)}
        });
        this.statEntries = Object.entries(roleCount).map(([key, val]) => ({
          label: this.translateService.instant(`role_${key.toLowerCase()}`),
          value: val,
        }));
        this.statEntries.push({
          label: this.translateService.instant('licensed'),
          value: licenseCount.used,
        });
        this.statEntries.push({
          label: this.translateService.instant('licensesAvailable'),
          value: licenseCount.available,
        });
        this.statEntries.push({
          label: this.translateService.instant('licenseExpiryDate'),
          value: this.globalService.dateToFrChLocale(licenseCount.expiryDate),
        });
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.dataService.updateMainTitle('');
    this.dataService.updateTitleExtension('');
  }

  private onGridReady(event: GridReadyEvent<User>) {
    const {api} = event;
    this.subscriptions.push(I18n.getColumns(this.translateService, api));
    this.presetClosedFilter(api);
  }

  private presetClosedFilter(gridApi: GridApi) {
    const closedFilterModel = gridApi.getColumnFilterModel('closed');

    if (!closedFilterModel) {
      gridApi.setColumnFilterModel('closed', {values: ['false']})
        .then(() => gridApi.onFilterChanged());
    }
  }

  gridFilterReset(event: GridResetEvent) {
    this.presetClosedFilter(event.api);
  }

  private getUserRoleTypesAsCodeTable(): Observable<CodeTableEntry[]> {
    if (!this.userRoleTypesAsCodeTable || this.userRoleTypesAsCodeTable.length === 0) {
      this.userRoleTypesAsCodeTable = [
        UserRoleType.ADMINISTRATOR,
        UserRoleType.CAMPAIGNCREATOR,
        UserRoleType.DISTRIBUTOR,
        UserRoleType.READONLY,
        UserRoleType.FRONTCAMPAIGNCREATOR,
        UserRoleType.DECENTRALIZEDCAMPAIGNCREATOR,
      ].map(d => this.userRoleTypeToCodeTableEntry(d));
    }
    return of(this.userRoleTypesAsCodeTable);
  }

  private userRoleTypeToCodeTableEntry(role?: UserRoleType): CodeTableEntry | undefined {
    if (!role) {
      return undefined;
    }
    // id will be the hash of the role, so it will be unique
    return {
      id: hashCode(role),
      ident: role,
      name: this.translateService.instant(`role_${role.toLowerCase()}`),
      closed: false,
    } as CodeTableEntry;
  }
}
