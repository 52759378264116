import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './core/views/login/login.component';
import { LicenseComponent } from './core/views/license/license.component';
import { HomeComponent } from './core/views/home/home.component';
import { PageComponent } from './shared/page/page.component';
import { AuthGuard } from './util/auth.guard';
import { ERoutePaths, EViewRoutes, EViewTitles } from './util/enum';
import { CallbackComponent } from './core/views/callback/callback.component';
import { ErrorCatalogueComponent } from './core/views/error-catalogue/error-catalogue.component';
import { PermissionGuard } from './util/permission.guard';
import { ErrorOverviewComponent } from './core/views/error-overview/error-overview.component';
import { ErrorResolver } from './util/resolvers';
import { RedirectComponent } from './shared/redirect/redirect.component';
import { SettingsComponent } from './core/views/settings/settings.component';
import { EProtectedActions } from './util/protected-actions';
import { IconsComponent } from './core/views/icons/icons.component';
import {LinkCreatorComponent} from "./core/views/link-creator/link-creator.component";

/**
 * Route definitions
 */
const routes: Routes = [
  {
    path: '',
    component: PageComponent,
    children: [
      {
        path: '',
        redirectTo: EViewRoutes.login,
        pathMatch: 'full',
      },
      {
        path: ERoutePaths.redirect,
        component: RedirectComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'home',
        component: HomeComponent,
        canActivate: [AuthGuard, PermissionGuard],
        data: {
          title: EViewTitles.home,
          viewRoute: EViewRoutes.home,
          allowedRoles: [],
        },
      },
      {
        path: ERoutePaths.errorAll,
        component: ErrorCatalogueComponent,
        canActivate: [AuthGuard, PermissionGuard],
        data: {
          title: EViewTitles.errors,
          viewRoute: EViewRoutes.errors,
          allowedRoles: [],
        },
      },
      {
        path: ERoutePaths.errorOverview,
        component: ErrorOverviewComponent,
        canActivate: [AuthGuard, PermissionGuard],
        data: {
          title: EViewTitles.errorOverview,
          viewRoute: EViewRoutes.errorOverview,
          allowedRoles: [],
        },
        resolve: {
          error: ErrorResolver,
        },
      },
      {
        path: 'settings',
        component: SettingsComponent,
        canActivate: [AuthGuard, PermissionGuard],
        data: {
          title: EViewTitles.settings,
          viewRoute: EViewRoutes.settings,
          allowedRoles: [EProtectedActions.isAdmin],
        },
      },
      {
        path: 'icons',
        component: IconsComponent,
        canActivate: [AuthGuard, PermissionGuard],
        data: {
          title: 'Icons (https://fonts.google.com/icons)',
          viewRoute: EViewRoutes.icons,
          allowedRoles: [EProtectedActions.isAdmin],
        },
      },
      {
        path: 'link-creator',
        component: LinkCreatorComponent,
        canActivate: [AuthGuard, PermissionGuard],
        data: {
          title: 'Redirect Link Creator',
          viewRoute: EViewRoutes.linkCreator,
          allowedRoles: [EProtectedActions.isAdmin],
        },
      },
    ],
  },
  {
    path: 'story',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./story/story.module').then((m) => m.StoryModule),
  },
  {
    path: 'campaign',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./campaign/campaign.module').then((m) => m.CampaignModule),
  },
  {
    path: 'product',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./product/product.module').then((m) => m.ProductModule),
  },
  {
    path: 'portfolio',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./portfolio/portfolio.module').then((m) => m.PortfolioModule),
  },
  {
    path: 'publication-type',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./publication-type/publication-type.module').then((m) => m.PublicationTypeModule),
  },
  {
    path: 'client',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./client/client.module').then((m) => m.ClientModule),
  },
  {
    path: 'intermediary',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./intermediary/intermediary.module').then((m) => m.IntermediaryModule),
  },
  {
    path: 'employee',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./employee/employee.module').then((m) => m.EmployeeModule),
  },
  {
    path: 'simulator',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./simulator/simulator.module').then((m) => m.SimulatorModule),
  },
  {
    path: 'monitoring',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./monitoring/monitoring.module').then((m) => m.MonitoringModule),
  },
  {
    path: 'objects',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./objects/objects.module').then((m) => m.ObjectsModule),
  },
  {
    path: 'distributor',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./distributor/distributor.module').then((m) => m.DistributorModule),
  },
  {
    path: 'my-settings',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./my-settings/my-settings.module').then((m) => m.MySettingsModule),
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: EViewTitles.login,
    },
  },
  {
    path: 'license',
    component: LicenseComponent,
    data: {
      title: EViewTitles.license,
    },
  },
  {
    path: 'callback',
    component: CallbackComponent,
    data: {
      title: EViewTitles.callback,
    },
  },
  {
    path: '**',
    redirectTo: EViewRoutes.home,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
