import {Component} from "@angular/core";
import {ColDef, GridApi, GridOptions, GridReadyEvent} from "ag-grid-community";
import {ReportLog, ReportService} from "../../../../api/core";
import {TranslateService} from "@ngx-translate/core";
import {GlobalService} from "../../../../services/global.service";
import {GridDataProvider} from "../../../../shared/grid/data-source";
import {genDateColumn, genEnumColumn} from "../../../../util/grid/grid-renderer.util";
import {genIconButtonColumn} from "../../../../shared/grid/cell-renderers/icon-button.renderer";

type DownloadProgress = {
  ident: string;
  current: number;
  total: number;
  progress: number;
  ready?: boolean;
}

@Component({
  selector: 'app-report-log-list',
  templateUrl: './report-log-list.component.html'
})
export class ReportLogListComponent {
  colDefs: ColDef[] = [
    {
      ...genIconButtonColumn({
        callback: (data: ReportLog) => this.onDownload(data),
        icon: 'download',
        tooltip: this.translateService.instant('download'),
        hidden: (data: ReportLog) => data.status != 'DONE'
      }),
      sortable: false
    },
    {field: 'ident', headerName: this.translateService.instant('ident')},
    {
      ...genDateColumn({
        field: 'createdAt',
        headerName: this.translateService.instant('creationDate')
      }),
      sort: 'desc'
    },
    genDateColumn({
      field: 'repliedAt',
      headerName: this.translateService.instant('lastUpdatedAt')
    }),
    genEnumColumn({
      field: 'status',
      headerName: this.translateService.instant('status'),
      values: ['PROCESSING', 'DONE', 'ERROR']
    }),
    {field: 'description', headerName: this.translateService.instant('description')}
  ];
  gridOptions: GridOptions = {
    rowHeight: 36,
    suppressContextMenu: true,
    suppressCellFocus: true,
    paginationAutoPageSize: true,
    defaultColDef: {
      suppressHeaderMenuButton: true,
    },
    onGridReady: (event: GridReadyEvent<any>) => (this.gridApi = event.api)
  };

  gridApi: GridApi;
  download: DownloadProgress;
  result: any[];

  data: GridDataProvider = this.reportService.getReportLogs.bind(this.reportService);

  constructor(
    private readonly translateService: TranslateService,
    private readonly globalService: GlobalService,
    private readonly reportService: ReportService,
  ) {
  }

  onDownload(data: ReportLog) {
    this.download = {
      ident: data.ident,
      current: 0,
      total: 0,
      progress: 0
    };
    this.result = [];
    this.downloadResult();
  }

  private downloadResult() {
    const download = this.download;
    this.reportService.downloadResult(download.ident, download.current).subscribe(result => {
      if (result.result == null) {
        download.ready = true;
        return;
      }
      this.result.push(result.result);
      download.total = result.total;
      download.current = download.current + 1;
      download.progress = Math.floor(100.0 * download.current / download.total);
      setTimeout(() => this.downloadResult());
    });
  }

  onDownloadResult() {
    const filename = `report_${this.download.ident}.json`;
    const jsonData = JSON.stringify(this.result, null, 2);
    this.save(filename, jsonData);
  }

  private save(filename: string, jsonData: string) {
    const blob = new Blob([jsonData], {type: 'application/json'});
    const elem = window.document.createElement('a');
    elem.href = URL.createObjectURL(blob);
    elem.target = '_blank';
    elem.download = filename;
    document.body.appendChild(elem);
    elem.click();
    document.body.removeChild(elem);
    this.download = undefined;
  }
}
