import {Component, ViewChild} from "@angular/core";
import {ColDef, GridOptions, ValueFormatterParams} from "ag-grid-community";
import {GridComponent} from "../../../../shared/grid/grid.component";
import {CodeTableEntry, OutgoingMessage, OutgoingMessageService, OutgoingMessageStatus} from "../../../../api/core";
import {TranslateService} from "@ngx-translate/core";
import {GlobalService} from "../../../../services/global.service";
import {GridDataProvider} from "../../../../shared/grid/data-source";
import {genDateColumn, genEnumColumn, genTextColumn} from "../../../../util/grid/grid-renderer.util";
import {genIconButtonColumn} from "../../../../shared/grid/cell-renderers/icon-button.renderer";

@Component({
  selector: 'app-outgoing-messages',
  templateUrl: './outgoing-messages.component.html'
})
export class OutgoingMessagesComponent {
  @ViewChild('grid') grid: GridComponent;
  hubs: CodeTableEntry[];
  colDefs: ColDef[] = [
    {
      ...genTextColumn(
        'group',
        this.translateService.instant('group')
      ),
      hide: true
    },
    genTextColumn(
      'topic',
      this.translateService.instant('topic')
    ),
    genTextColumn(
      'key',
      this.translateService.instant('key')
    ),
    genDateColumn({
      field: 'createdAt',
      headerName: this.translateService.instant('creationDate'),
      dateFormatter: (params: ValueFormatterParams) =>
        [
          this.globalService.dateToFrChLocale(params.data.createdAt),
          this.globalService.timeToFrChLocale(params.data.createdAt),
        ].join(' ')
    }),
    genDateColumn({
      field: 'updatedAt',
      headerName: this.translateService.instant('lastUpdatedAt'),
      dateFormatter: (params: ValueFormatterParams) =>
        [
          this.globalService.dateToFrChLocale(params.data.updatedAt),
          this.globalService.timeToFrChLocale(params.data.updatedAt),
        ].join(' ')
    }),
    genEnumColumn({
      field: 'status',
      headerName: this.translateService.instant('status'),
      values: Object.values(OutgoingMessageStatus)
    }),
    genTextColumn(
      'failedMessage',
      this.translateService.instant('error'),
    ),
    genIconButtonColumn({
      callback: (data: OutgoingMessage) => this.onRetry(data),
      icon: 'refresh',
      tooltip: this.translateService.instant('retry'),
      hidden: data => data.status != 'FAILED'
    })
  ];
  gridOptions: GridOptions = {
    rowHeight: 36,
    suppressContextMenu: true,
    suppressCellFocus: true,
    paginationAutoPageSize: true,
  };
  data: GridDataProvider = this.outgoingMessageService.getMessages.bind(this.outgoingMessageService);

  constructor(
    private readonly translateService: TranslateService,
    private readonly globalService: GlobalService,
    private readonly outgoingMessageService: OutgoingMessageService,
  ) {

  }

  onRefresh() {
    this.grid.reload();
  }

  onRetry(row: OutgoingMessage) {
    this.outgoingMessageService.retryMessage(row.id)
      .subscribe(_ => {
        this.onRefresh();
      })
  }
}
